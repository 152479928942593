.r-head{
    margin-bottom: 2rem;
    padding: 1rem;
    
}
.r-wrapper{
    margin: 3rem;
}
.r-card{
  gap: 0.6rem;
  padding: 1rem;
  border-radius: 10px;
  max-width: max-content;
  transition: all 300ms ease-in;
  margin-bottom: 2rem;
  
  
 
}
.r-card>img{
    max-width: 25rem;
    width: 100%;

}
.r-card>:nth-child(2){
    font-size: 1rem;
}
.r-card>:nth-child(4){
    font-size: 1rem;
    width: 15rem;
    display: block;
}
.r-card>:nth-child(3){
    font-size: 2rem;
}
.r-card:hover{
    scale: 1.025;
    
cursor: pointer;
box-shadow: 70px 8px 50px -50px rgb(70, 61, 61) ;
}
.swiper-horizontal{
    overflow: visible;
}

