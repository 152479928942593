.g-container{
    width: 80vw;
    height: 35vh;
    border-radius: 25px;
    background-color: rgb(11, 57, 141);
    display: flex;
    align-items: center;
   justify-content: center;
    margin: 9rem;
    flex-direction: column;
}

.g-container>:nth-child(1){
    font-size: 3rem;
}
.g-container>:nth-child(2){
    font-size: 1.5rem;
    color: white;
}
.g-container>:nth-child(4){
    font-size: 2rem;
}
